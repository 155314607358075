import { useModal } from 'vue-final-modal';
import { defineAsyncComponent } from '#imports';

export const useDeleteQuestionnaire = () => {
  const deleteQuestionnaire = () => {
    const { open, close } = useModal({
      modalId: 'confirmationDeleteProfile',
      component: defineAsyncComponent(
        () => import('@/components/popups/ConfirmationDeleteQuestionnaire.vue'),
      ),
      attrs: {
        closeModal: () => {
          close();
        },
      },
    });

    open();
  };

  return {
    deleteQuestionnaire,
  };
};
