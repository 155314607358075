<script setup>
import {
  definePageMeta,
  computed,
  reactive,
  ref,
  defineAsyncComponent,
  onBeforeMount,
  useRuntimeConfig,
} from '#imports';
import { useRouter } from '#imports';
import { useModal } from 'vue-final-modal';

import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';

import useGlobalRequestsProgress from '@/composables/useGlobalRequestsProgress';
import { useFormHelp } from '@/composables/useFormHelp';
import { useSeo } from '@/composables/useSeo';
import { useMessagePopup } from '@/composables/useMessagePopup';

import { formatValues, toFormData } from '@/utils/helpers';

import PersonalInformationForm from '@/components/forms/auth/PersonalInformationForm.vue';
import PasswordFormLayout from '@/components/layout/forms/PasswordFormLayout.vue';
import AuthLayout from '@/components/layout/AuthLayout.vue';
import { YandexSmartCaptcha } from '@gladesinger/vue3-yandex-smartcaptcha';

definePageMeta({
  middleware: ['is-authorized'],
});

useSeo({ title: 'Регистрация | Discovermore.ru' });

const authStore = useAuthStore();
const router = useRouter();
const formHelpComposable = useFormHelp();
const { waitRequest } = useGlobalRequestsProgress();
const userStore = useUserStore();
const configRuntime = useRuntimeConfig();
const { openMessagePopup } = useMessagePopup();

// <editor-fold desc="Steps">
const totalSteps = 6;
const typesDictionary = {
  personalInformation: 'personalInformation',
  phoneConfirmation: 'phoneConfirmation',
  passportData: 'passportData',
  emailConfirmation: 'emailConfirmation',
  survey: 'survey',
  password: 'password',
  failedPassport: 'failedPassport',
  finish: 'finish',
  error: 'error',
};
const stepsDictionary = {
  PHONE_STEP_1: 'step_2_1',
  PHONE_STEP_2: 'step_2_2',
  PASSPORT_STEP_2: 'step_3_2',
};
const phoneConfirmationData = reactive({
  phoneNumberForDialing: null,
  isCall: false,
  isBlockedChangePhone: false,
});
const currentStep = ref(typesDictionary.personalInformation);

const steps = computed(() => {
  return {
    [typesDictionary.personalInformation]: {
      component: {
        value: PersonalInformationForm,
        data: {
          heading: 'Персональные данные',
          text: 'Заполните все поля анкеты',
          stepText: `Регистрация | Шаг 1 из ${totalSteps}`,
          submitForm: sendPersonalInformation,
        },
      },
      steps: {
        index: 1,
        next: {
          value: typesDictionary.phoneConfirmation,
        },
      },
    },
    [typesDictionary.phoneConfirmation]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/layout/forms/ConfirmationPhoneLayout.vue'),
        ),
        data: {
          submitForm: sendPhoneConfirmation,
          requestCallAgain,
          heading: 'Подтверждение телефона',
          stepText: `Регистрация | Шаг 2 из ${totalSteps}`,
          infoText: `Для продолжения регистрации, пожалуйста, позвоните с номера телефона <strong>${formatValues.phone(formData.mobilePhone)}</strong> по номеру, указанному ниже. Звонок бесплатный. Переход произойдет автоматически в течение пяти секунд после звонка`,
          infoTextImportant: `Обратите внимание, что аккаунт с неподтвержденным номером телефона будет удален в течение <strong>24 часов</strong>`,
          buttonData: {
            text: 'Изменить мой номер',
            action: openConfirmationPhonePopup,
          },
          phoneNumberForDialing: phoneConfirmationData.phoneNumberForDialing,
          isCall: phoneConfirmationData.isCall,
          isBlockedChangePhone: phoneConfirmationData.isBlockedChangePhone,
        },
      },
      steps: {
        index: 2,
        next: {
          value: typesDictionary.passportData,
        },
      },
    },
    [typesDictionary.passportData]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/forms/auth/PassportForm.vue'),
        ),
        data: {
          submitForm: sendPassportData,
          heading: 'Паспортные данные ',
          stepText: `Регистрация | Шаг 3 из ${totalSteps}`,
        },
      },
      steps: {
        index: 3,
        next: {
          value: typesDictionary.emailConfirmation,
        },
      },
    },
    [typesDictionary.emailConfirmation]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/forms/auth/ConfirmationEmailForm.vue'),
        ),
        data: {
          requestEmailAgain,
          heading: 'Подтверждение E-mail',
          stepText: `Регистрация | Шаг 4 из ${totalSteps}`,
          buttonData: {
            text: 'Изменить мой e-mail',
            action: openConfirmationEmailPopup,
          },
        },
      },
      steps: {
        index: 4,
        next: {
          value: typesDictionary.survey,
        },
      },
    },
    [typesDictionary.survey]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/forms/survey/SurveyForm.vue'),
        ),
        data: {
          heading: 'Опрос',
          stepText: `Регистрация | Шаг 5 из ${totalSteps}`,
          submitForm: sendSurvey,
        },
      },
      steps: {
        index: 5,
        next: {
          value: typesDictionary.password,
        },
      },
    },
    [typesDictionary.password]: {
      component: {
        value: PasswordFormLayout,
        data: {
          heading: 'Установка нового пароля',
          stepText: `Регистрация | Шаг 6 из ${totalSteps}`,
          infoText:
            'Пароль должен состоять как минимум из 8 символов, содержать буквы латинского алфавита обоих регистров, цифры или специальные символы',
          submitForm: setPassword,
        },
      },
      steps: {
        index: 6,
        next: {
          value: typesDictionary.finish,
        },
      },
    },
    [typesDictionary.finish]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/sections/Status.vue'),
        ),
        data: {
          heading: 'Благодарим за регистрацию!<br/> Проверяем ваши данные',
          description:
            'Ваш профиль сейчас находится на проверке. Обычно это занимает несколько минут, но иногда процесс может продлиться до&nbsp;3&nbsp;дней.<br/> Мы уведомим вас по электронной почте сразу после завершения проверки.',
          buttonData: {
            text: 'Перейти на главную',
            action: () => router.push('/'),
          },
          sendAnalytic: () => sendAnalyticRegistrationSuccess(),
        },
      },
      steps: {
        index: 7,
      },
    },
    [typesDictionary.failedPassport]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/sections/Status.vue'),
        ),
        data: {
          heading: 'Документы не прошли проверку',
          description:
            'К сожалению, Ваши анкетные данные не прошли проверку. Анкета будет автоматически удалена в течение <strong>24&nbsp;часов</strong>.после этого вы сможете повторить регистрацию. ',
          buttonData: {
            text: 'на главную',
            action: () => {
              authStore.logout();
              router.push('/');
            },
          },
        },
      },
      steps: {
        index: 8,
      },
    },
    [typesDictionary.error]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/sections/Status.vue'),
        ),
        data: {
          heading: 'Произошла ошибка',
          title: 'Необходимо повторить регистрацию',
          description:
            'К сожалению, Ваши анкетные данные не прошли проверку. Анкета будет автоматически удалена в течение <strong>24&nbsp;часов</strong>.',
          buttonData: {
            text: 'на главную',
            action: () => {
              authStore.logout();
              router.push('/');
            },
          },
        },
      },
      steps: {
        index: 9,
      },
    },
  };
});

const currentStepData = computed(() => {
  return steps.value[currentStep.value];
});

const currentComponent = computed(() => {
  return currentStepData.value?.component;
});

const currentComponentData = computed(() => {
  return currentComponent.value?.data;
});

const nextStep = computed(() => {
  return currentStepData.value?.steps?.next?.value;
});

function changeStep(step) {
  const isValid = Object.keys(steps.value).includes(step);

  if (!isValid) return;

  currentStep.value = typesDictionary[step];
}
// </editor-fold>

// <editor-fold desc="FORM">
const formData = reactive({
  //first step -> personalInformation
  first_name: '',
  last_name: '',
  middle_name: '',
  region: '',
  regionData: '',
  birthdate: '',
  gender: null,
  mobilePhone: userStore.user?.personalData?.mobilePhone || '',
  email: userStore.user?.personalData?.email || '',
  is_agree: false,
  is_receive_newsletter: false,

  //second step -> passportData
  files: null,

  //third step -> phoneConfirmation
  code: '',

  //fifth step -> password
  password: '',
  password_confirmation: '',
  //survey
  brand: null,
  primaryCigaretteSubfamily: null,
  brandAll: null,
  secondaryCigaretteSubfamily: null,
});
const isPendingCheckRegisterStatus = ref(true);
const yaCaptchaRef = ref(null);
let checkRegisterStatusTimeout = null;
let formActionsPersonalInformation = null;

function updateForm({ key, value }) {
  formData[key] = value;
}

function getStepFromBackend(stepValue) {
  if (!stepValue) return;

  const [, stepIndex] = stepValue.split('_');

  if (stepValue === stepsDictionary.PASSPORT_STEP_2)
    return typesDictionary.passportData;

  if (stepIndex === typesDictionary.finish) return typesDictionary.finish;

  return Object.entries(steps.value).reduce((acc, [key, value]) => {
    if (value.steps.index === Number(stepIndex)) acc = key;

    return acc;
  }, '');
}

async function recursiveCheckRegisterStatus(additionalRecursiveCondition) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (res, rej) => {
    try {
      if (checkRegisterStatusTimeout) clearTimeout(checkRegisterStatusTimeout);

      const data = await authStore.checkRegisterStatus();

      if (
        data.nextStep !== null &&
        data.nextStep !== additionalRecursiveCondition
      )
        return res(data);

      checkRegisterStatusTimeout = setTimeout(async () => {
        return res(recursiveCheckRegisterStatus(additionalRecursiveCondition));
      }, 3000);
    } catch (e) {
      return rej(e);
    }
  });
}

function setPhoneConfirmationStepData(data) {
  const { phoneConfirmation, type } = data;

  phoneConfirmationData.phoneNumberForDialing = phoneConfirmation;
  phoneConfirmationData.isCall = type === 'beeline';

  if (phoneConfirmationData.isCall) {
    phoneConfirmationData.isBlockedChangePhone = false;

    recursiveCheckRegisterStatus(stepsDictionary.PHONE_STEP_1)
      .then(() => {
        phoneConfirmationData.isBlockedChangePhone = true;

        return waitRequest(() =>
          recursiveCheckRegisterStatus(stepsDictionary.PHONE_STEP_2),
        );
      })
      .then(async (data) => {
        sendAnalyticMobileVerificationSuccess();

        const { nextStep } = data;

        const nextStepSlug = getStepFromBackend(nextStep);

        if (nextStepSlug === typesDictionary.emailConfirmation) {
          await authStore.sendEmailVerificationRequest();
        }

        changeStep(nextStepSlug);
      });
  }
}

function sendPersonalInformation(values, actions) {
  formActionsPersonalInformation = actions;

  yaCaptchaRef.value.execute();
}

function sendPersonalInformationWithCaptcha(token) {
  yaCaptchaRef.value.reset();

  waitRequest(() =>
    authStore
      .sendRegistration({ ...formData, captcha: token })
      .then(() => {
        sendAnalyticPersonDataSuccess();
      })
      .then(() => recursiveCheckRegisterStatus())
      .then(() => authStore.sendPhoneVerificationRequest())
      .then((data) => {
        setPhoneConfirmationStepData(data);
        changeStep(typesDictionary.phoneConfirmation);
      })
      .catch(({ data }) => {
        formHelpComposable.checkErrors(
          data,
          formActionsPersonalInformation.setErrors,
          sendAnalyticRegistrationError,
          {
            step: 'Персональные данные',
          },
        );
      }),
  );
}

function sendPassportData(data, actions) {
  const passportData = toFormData({ file: formData.files[0] });
  let successPassportVerification = false;

  waitRequest(() =>
    authStore
      .sendPassportData(passportData)
      .then(() => recursiveCheckRegisterStatus())
      .then((data) => {
        const { nextStep } = data;

        const nextStepSlug = getStepFromBackend(nextStep);

        if (
          [
            typesDictionary.failedPassport,
            typesDictionary.finish,
            typesDictionary.passportData,
          ].includes(nextStepSlug)
        ) {
          if (nextStepSlug === typesDictionary.passportData) {
            formData.files = null;

            actions.resetForm();

            actions.setErrors({
              files: 'Необходимо загрузить новое фото документа',
            });

            openMessagePopup({
              text: 'Необходимо загрузить новое фото документа',
              isSuccess: false,
            });

            sendAnalyticRegistrationError({
              errorsName: 'Необходимо загрузить новое фото документа',
              step: 'Паспортные данные',
            });
          }

          successPassportVerification = false;

          return changeStep(nextStepSlug);
        }

        successPassportVerification = true;

        sendAnalyticPassportDataSuccess();

        return authStore.sendEmailVerificationRequest();
      })
      .then(() => {
        if (!successPassportVerification) return;

        changeStep(nextStep.value);
      })
      .catch(({ data }) =>
        formHelpComposable.checkErrors(
          data,
          actions.setErrors,
          sendAnalyticRegistrationError,
          {
            step: 'Паспортные данные',
          },
        ),
      ),
  );
}

function sendPhoneConfirmation(values, actions) {
  authStore
    .sendPhoneVerification({ code: formData.code })
    .then(() => authStore.sendEmailVerificationRequest())
    .then(() => {
      changeStep(nextStep.value);
    })
    .catch(({ data }) =>
      formHelpComposable.checkErrors(
        data,
        actions.setErrors,
        sendAnalyticRegistrationError,
        {
          step: 'Подтверждение телефона',
        },
      ),
    );
}

function requestCallAgain(values, actions) {
  return authStore
    .sendPhoneVerificationRequest()
    .then((data) => {
      setPhoneConfirmationStepData(data);
    })
    .catch(({ data }) =>
      formHelpComposable.checkErrors(data, actions.setErrors),
    );
}

function changePhone(values, actions, closeModal) {
  formData.mobilePhone = values.mobilePhone;
  const phone = formatValues.phoneOnlyNumbers(values.mobilePhone);

  authStore
    .changePhone(phone)
    .then((data) => {
      setPhoneConfirmationStepData(data);
      closeModal();
    })
    .catch(({ data }) =>
      formHelpComposable.checkErrors(data, actions.setErrors),
    );
}

function requestEmailAgain(values, actions) {
  authStore
    .sendEmailVerificationRequest()
    .then(() => {
      // sendAnalyticEmailVerificationSuccess();
    })
    .catch(({ data }) =>
      formHelpComposable.checkErrors(
        data,
        actions.setErrors,
        sendAnalyticRegistrationError,
        {
          step: 'Подтверждение email',
        },
      ),
    );
}

function changeMail(values, actions) {
  formData.email = values.email;

  authStore
    .changeEmailVerification({ email: formData.email })
    .catch(({ data }) =>
      formHelpComposable.checkErrors(data, actions.setErrors),
    );
}

function setPassword(data, actions) {
  waitRequest(() =>
    authStore
      .setRegistrationPassword({
        password: formData.password,
        password_confirmation: formData.password_confirmation,
      })
      .then(() => recursiveCheckRegisterStatus())
      .then((data) => {
        const { nextStep } = data;

        const nextStepSlug = getStepFromBackend(nextStep);

        changeStep(nextStepSlug);

        sendAnalyticPasswordSuccess();
      })
      .catch(({ data }) =>
        formHelpComposable.checkErrors(
          data,
          actions.setErrors,
          sendAnalyticRegistrationError,
          {
            step: 'Установка пароля',
          },
        ),
      ),
  );
}

function openConfirmationEmailPopup() {
  const { open, close } = useModal({
    modalId: 'confirmationEmail',
    component: defineAsyncComponent(
      () => import('@/components/popups/ConfirmationEmail'),
    ),
    attrs: {
      emailData: formData.email,
      submitForm: changeMail,
      closeModal: () => {
        close();
      },
    },
  });

  open();
}

function openConfirmationPhonePopup() {
  const { open, close } = useModal({
    modalId: 'confirmationPhone',
    component: defineAsyncComponent(
      () => import('@/components/popups/ChangePhone.vue'),
    ),
    attrs: {
      phoneData: formData.mobilePhone,
      submitForm: changePhone,
      closeModal: () => {
        close();
      },
    },
  });

  open();
}

function sendSurvey(values, actions) {
  const data = {
    primaryCigaretteSubfamily: values.primaryCigaretteSubfamily?.spicId,
    secondaryCigaretteSubfamily: values.secondaryCigaretteSubfamily?.spicId,
  };

  authStore
    .sendRegisterSurvey(data)
    .then(() => {
      sendAnalyticSurveySuccess();

      changeStep(nextStep.value);
    })
    .catch(({ data }) => {
      formHelpComposable.checkErrors(data, actions.setErrors);
    });
}

onBeforeMount(() => {
  waitRequest(() =>
    authStore
      .checkRegisterStatus()
      .then((data) => {
        const { nextStep } = data;

        const nextStepSlug = getStepFromBackend(nextStep);

        changeStep(nextStepSlug);

        if (nextStep === stepsDictionary.PHONE_STEP_2)
          return recursiveCheckRegisterStatus(
            stepsDictionary.PHONE_STEP_2,
          ).then((data) => {
            const { nextStep } = data;

            const nextStepSlug = getStepFromBackend(nextStep);

            changeStep(nextStepSlug);
          });

        if (nextStepSlug === typesDictionary.phoneConfirmation) {
          if (authStore.phoneVerificationUUID) return;

          return requestCallAgain();
        }
      })
      .catch(() => {
        if (!authStore.accessToken) return;

        changeStep(typesDictionary.error);
      })
      .finally(() => {
        isPendingCheckRegisterStatus.value = false;
      }),
  );
});
// </editor-fold>

//<editor-fold desc="ANALYTIC">
import { authAnalytic } from '@/analytic/google/auth';

function sendAnalyticPersonDataSuccess() {
  authAnalytic.registrationPersonDataSuccess();
}

function sendAnalyticMobileVerificationSuccess() {
  authAnalytic.registrationMobileVerificationSuccess();
}

function sendAnalyticPassportDataSuccess() {
  authAnalytic.registrationPassportDataSuccess();
}

function sendAnalyticSurveySuccess() {
  authAnalytic.registrationSurveySuccess();
}

function sendAnalyticPasswordSuccess() {
  authAnalytic.registrationPasswordSuccess();
}

function sendAnalyticRegistrationSuccess() {
  authAnalytic.registrationSuccess();
}

function sendAnalyticRegistrationError(data) {
  authAnalytic.registrationError(data.errorsName, data.step);
}
//</editor-fold>
</script>

<template>
  <AuthLayout
    class="registration-page"
    :class="{
      [`registration-page--step--${currentStep}`]: currentStep,
    }"
  >
    <template #content>
      <client-only>
        <YandexSmartCaptcha
          ref="yaCaptchaRef"
          :site-key="configRuntime?.public?.recaptchaKey"
          :invisible="true"
          shield-position="bottom-right"
          @on-success="sendPersonalInformationWithCaptcha"
        />
      </client-only>

      <transition name="fade" mode="out-in">
        <component
          :is="currentComponent.value"
          v-if="currentComponent?.value && !isPendingCheckRegisterStatus"
          :key="currentStep"
          :form-data="formData"
          :component-data="currentComponentData"
          class="registration-page__component"
          @update-form="updateForm"
        />
      </transition>
    </template>
  </AuthLayout>
</template>

<style scoped lang="scss">
.registration-page {
  $parent: &;

  &__component {
    width: em(800);

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
</style>
