<script setup>
import { boolean, mixed, object, string } from 'yup';
import { Form as VeeForm } from 'vee-validate';
import { computed, ref, useNuxtApp } from '#imports';

import { useGlobalStore } from '@/stores/global';

import { usePolicePopup } from '@/composables/usePolicePopup';

import { police } from '@/configs/police';

import UiInput from '@/components/ui/form/UiInput.vue';
import UiMaskInput from '@/components/ui/form/UiMaskInput.vue';
import UiSelect from '@/components/ui/form/UiSelect.vue';
import UiCheckbox from '@/components/ui/form/UiCheckbox.vue';
import UiButtonWithLoader from '@/components/ui/button/UiButtonWithLoader.vue';
import FormLayout from '@/components/layout/forms/FormLayout.vue';
import CAutocomplete from '@/components/common/autocomplete/CAutocomplete.vue';
import UiButtonIcon from '@/components/ui/button/UiButtonIcon.vue';

const { openPolicePopup } = usePolicePopup();
const globalStore = useGlobalStore();

const { $tp } = useNuxtApp();

const props = defineProps({
  formData: {
    type: Object,
    default: () => ({}),
  },
  componentData: {
    type: Object,
    default: () => ({}),
  },
});

const emits = defineEmits(['update-form']);

//<editor-fold desc="Form">
const genderList = [
  { value: 'male', name: 'Мужской' },
  { value: 'female', name: 'Женский' },
];

const middleNameData = computed({
  get() {
    return props.formData.middle_name;
  },
  set(value) {
    emits('update-form', { key: 'middle_name', value });
  },
});

const nameData = computed({
  get() {
    return props.formData.first_name;
  },
  set(value) {
    emits('update-form', { key: 'first_name', value });
  },
});

const lastNameData = computed({
  get() {
    return props.formData.last_name;
  },
  set(value) {
    emits('update-form', { key: 'last_name', value });
  },
});

const birthdateData = computed({
  get() {
    return props.formData.birthdate;
  },
  set(detail = {}) {
    emits('update-form', { key: 'birthdate', value: detail.masked });
  },
});

const genderData = computed({
  get() {
    return props.formData.gender;
  },
  set(value) {
    emits('update-form', { key: 'gender', value });
  },
});

const regionData = computed({
  get() {
    return props.formData.region;
  },
  set(value) {
    emits('update-form', { key: 'region', value });
  },
});

const phoneData = computed({
  get() {
    return props.formData.mobilePhone;
  },
  set(detail = {}) {
    emits('update-form', { key: 'mobilePhone', value: detail.masked });
  },
});

const emailData = computed({
  get() {
    return props.formData.email;
  },
  set(value) {
    emits('update-form', { key: 'email', value });
  },
});

const isAgreeData = computed({
  get() {
    return props.formData.is_agree;
  },
  set(value) {
    emits('update-form', { key: 'is_agree', value });
  },
});

const isReceiveNewsletterData = computed({
  get() {
    return props.formData.is_receive_newsletter;
  },
  set(value) {
    emits('update-form', { key: 'is_receive_newsletter', value });
  },
});

function sendForm(values, actions) {
  props.componentData?.submitForm?.(values, actions);
}
// </editor-fold>

//<editor-fold desc="Validation rules">
const validationSchema = object({
  middleName: string().required().trim().maxStringLength(100),
  firstName: string().required().trim().maxStringLength(100),
  lastName: string().required().trim().maxStringLength(100),
  region: string().required().trim().maxStringLength(300),
  birthDate: string().required().isValidBirthDate(),
  sex: mixed().required(),
  mobilePhone: string().required().trim().lengthPhone(11),
  email: string().required().trim().isValidEmail(),
  policy: boolean().required().isTrue(),
  newsletter: boolean().required().isTrue(),
});
// </editor-fold>

//<editor-fold desc="Autocomplete">
import { useRuntimeConfig } from '#imports';
const runtimeConfig = useRuntimeConfig();
const dadataApiKey = runtimeConfig.public.dadataApiKey;

const gender = ref('UNKNOWN');

function onSuggestionSelect({ data }) {
  if (data?.gender) {
    gender.value = data.gender;
  } else {
    gender.value = ref('UNKNOWN');
  }
}
function onRegionSelect({ data }) {
  emits('update-form', { key: 'regionData', value: data });
}
</script>
<template>
  <VeeForm
    v-slot="{ isSubmitting }"
    :validation-schema="validationSchema"
    class="personal-information-form"
    @submit="sendForm"
  >
    <FormLayout
      class="personal-information-form__form"
      :heading="props.componentData?.heading"
      :text="props.componentData?.text"
    >
      <template #heading>
        <div
          class="personal-information-form__font personal-information-form__font--step"
          v-html="props.componentData?.stepText"
        />
      </template>

      <template #content>
        <div
          class="personal-information-form__section personal-information-form__section--without-offset"
        >
          <div class="personal-information-form__hint">
            <p
              class="personal-information-form__font personal-information-form__font--hint"
            >
              Поля с * — обязательные для заполнения
            </p>
          </div>

          <CAutocomplete
            v-model="lastNameData"
            :token="dadataApiKey"
            type="fio"
            :locations="{ parts: ['surname'], gender }"
            class="personal-information-form__field"
            @on-selected="onSuggestionSelect"
          >
            <template #input="{ onInput, onFocus }">
              <UiInput
                v-model="lastNameData"
                name="lastName"
                label="Фамилия*"
                placeholder="Фамилия*"
                class="personal-information-form__field"
                @input="onInput"
                @focus="onFocus"
              />
            </template>
          </CAutocomplete>

          <CAutocomplete
            v-model="nameData"
            :token="dadataApiKey"
            type="fio"
            :locations="{ parts: ['name'], gender }"
            class="personal-information-form__field"
            @on-selected="onSuggestionSelect"
          >
            <template #input="{ onInput, onFocus }">
              <UiInput
                v-model="nameData"
                name="firstName"
                label="Имя*"
                placeholder="Имя*"
                class="personal-information-form__field"
                @input="onInput"
                @focus="onFocus"
              />
            </template>
          </CAutocomplete>

          <CAutocomplete
            v-model="middleNameData"
            :token="dadataApiKey"
            type="fio"
            :locations="{ parts: ['patronymic'], gender }"
            class="personal-information-form__field"
            @on-selected="onSuggestionSelect"
          >
            <template #input="{ onInput, onFocus }">
              <UiInput
                v-model="middleNameData"
                name="middleName"
                label="Отчество*"
                placeholder="Отчество*"
                class="personal-information-form__field"
                @input="onInput"
                @focus="onFocus"
              />
            </template>
          </CAutocomplete>

          <div class="personal-information-form__group">
            <div
              class="personal-information-form__item personal-information-form__item--half"
            >
              <UiMaskInput
                v-model="birthdateData"
                mask="##.##.####"
                unmasked
                type="date"
                name="birthDate"
                input-mode="numeric"
                label="Дата рождения*"
                placeholder="Дата рождения*"
                class="personal-information-form__field"
              />
            </div>

            <div
              class="personal-information-form__item personal-information-form__item--half"
            >
              <UiSelect
                v-model="genderData"
                :options="genderList"
                label="Пол*"
                placeholder="Пол*"
                show-errors
                name="sex"
                track-displayed-by="name"
                track-by="value"
                is-clear-button
                class="personal-information-form__field"
              />
            </div>
          </div>

          <CAutocomplete
            v-model="regionData"
            :token="dadataApiKey"
            class="personal-information-form__field"
            @on-selected="onRegionSelect"
          >
            <template #input="{ onInput, onFocus }">
              <UiInput
                v-model="regionData"
                name="region"
                label="Регион*"
                placeholder="Регион*"
                class="personal-information-form__field"
                @input="onInput"
                @focus="onFocus"
              />
            </template>
          </CAutocomplete>

          <UiMaskInput
            v-model="phoneData"
            :mask="'+7 (###) ### ## ##'"
            unmasked
            name="mobilePhone"
            label="Телефон*"
            placeholder="+7("
            class="personal-information-form__field"
            input-mode="numeric"
          />

          <UiInput
            v-model="emailData"
            name="email"
            label="E-mail*"
            placeholder="E-mail*"
            class="personal-information-form__field"
          />
        </div>

        <div class="personal-information-form__section">
          <div
            class="personal-information-form__item personal-information-form__item--checkbox"
          >
            <UiCheckbox
              v-model="isAgreeData"
              name="policy"
              class="personal-information-form__field"
            >
              <span
                class="personal-information-form__font personal-information-form__font--checkbox"
              >
                Я даю
                <button
                  type="button"
                  @click="openPolicePopup(police.personalData)"
                >
                  согласие
                </button>
                {{
                  $tp(
                    ' на обработку персональных данных и подтверждаю ознакомление с',
                  )
                }}
                <button
                  type="button"
                  @click="openPolicePopup(police.privacyPolicy)"
                >
                  Политикой о конфиденциальности
                </button>

                {{ $tp(' и') }}

                <button
                  type="button"
                  @click="openPolicePopup(police.termsConditions)"
                >
                  Пользовательским соглашением
                </button>
              </span>
            </UiCheckbox>
          </div>

          <div
            class="personal-information-form__item personal-information-form__item--checkbox"
          >
            <UiCheckbox
              v-model="isReceiveNewsletterData"
              name="newsletter"
              class="personal-information-form__field"
            >
              <span
                class="personal-information-form__font personal-information-form__font--checkbox"
              >
                {{
                  $tp(
                    'Я даю согласие на получение информационной рассылки от ООО «Дж.Т.И. Россия», включая передачу коротких текстовых сообщений, в том числе всплывающих сообщений',
                  )
                }}
              </span>
            </UiCheckbox>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="personal-information-form__footer">
          <UiButtonWithLoader
            type="submit"
            class="personal-information-form__button personal-information-form__submit"
            text="Продолжить"
            :is-loading="isSubmitting || globalStore.requestsProgress"
          />

          <div class="personal-information-form__links">
            <div class="personal-information-form__text">
              <p
                class="personal-information-form__font personal-information-form__font--text"
              >
                Уже зарегистрированы?
              </p>
            </div>

            <UiButtonIcon
              theme="transparent"
              to="/auth/login"
              class="personal-information-form__button personal-information-form__button--login"
              text="Войти"
            />
          </div>
        </div>
      </template>
    </FormLayout>
  </VeeForm>
</template>
<style scoped lang="scss">
.personal-information-form {
  &__font {
    &--step {
      font-size: em(18);
      line-height: 1.3;
      color: $color-grey-3;
    }

    &--text {
      font-size: em(14);
      font-weight: 600;
      line-height: 1;
      color: $color-grey-3;
    }

    &--hint {
      font-size: em(13);
      line-height: 1.2;
      color: $color-grey-3;
    }

    &--checkbox {
      font-size: em(13);
      line-height: 1.2;

      &:deep(button) {
        display: inline;
        font-weight: 700;
        transition: $time-default ease-out;
        transition-property: color;

        @include hover {
          color: $color-blue-1;
        }
      }
    }
  }

  &__group {
    display: flex;
    gap: em(16);

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__item {
    &--half {
      width: 50%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: em(16);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__button {
    &--submit {
      width: 100%;
    }
  }

  &__links {
    display: flex;
    gap: em(8);
    align-items: center;
    justify-content: center;
  }

  &__hint {
    margin-bottom: em(16);
  }
}
</style>
