import { useGlobalStore } from '@/stores/global';
import { computed } from '#imports';

export default () => {
  const globalStore = useGlobalStore();

  return {
    waitRequest(callback) {
      if (globalStore.requestsProgress) return;

      globalStore.changeRequestProgress(true);

      return callback().finally(() => {
        globalStore.changeRequestProgress(false);
      });
    },
    progress: computed(() => globalStore.requestsProgress),
  };
};
