<script setup>
import { object, ref, string } from 'yup';
import { Form as VeeForm } from 'vee-validate';
import { computed, onMounted } from '#imports';

import { useGlobalStore } from '@/stores/global';

import { useDeleteQuestionnaire } from '@/composables/useDeleteQuestionnaire';

import UiInput from '@/components/ui/form/UiInput.vue';
import UiButtonWithLoader from '@/components/ui/button/UiButtonWithLoader.vue';
import FormLayout from '@/components/layout/forms/FormLayout.vue';
import UiButtonIcon from '@/components/ui/button/UiButtonIcon.vue';

const props = defineProps({
  formData: {
    type: Object,
    default: () => ({}),
  },
  componentData: {
    type: Object,
    default: () => ({}),
  },
});

const emits = defineEmits(['update-form']);

const { deleteQuestionnaire } = useDeleteQuestionnaire();
const globalStore = useGlobalStore();
//<editor-fold desc="Form">
const passwordData = computed({
  get() {
    return props.formData.password;
  },
  set(value) {
    emits('update-form', { key: 'password', value });
  },
});

const passwordConfirmationData = computed({
  get() {
    return props.formData.password_confirmation;
  },
  set(value) {
    emits('update-form', { key: 'password_confirmation', value });
  },
});

function sendForm(values, actions) {
  props.componentData?.submitForm?.(values, actions);
}
// </editor-fold>

//<editor-fold desc="Validation rules">
const validationSchema = object({
  password: string().required().authPassword(),
  password_confirmation: string()
    .required()
    .oneOf([ref('password'), null], 'Пароли должны совпадать'),
});
// </editor-fold>
</script>
<template>
  <VeeForm
    v-slot="{ isSubmitting }"
    :validation-schema="validationSchema"
    class="password-form-layout"
    @submit="sendForm"
  >
    <FormLayout
      :heading="props.componentData?.heading"
      :text="props.componentData?.text"
      class="password-form-layout"
    >
      <template #heading>
        <div
          class="password-form-layout__font password-form-layout--step"
          v-html="props.componentData?.stepText"
        />
      </template>

      <template #content>
        <div class="password-form-layout__section">
          <div class="password-form-layout__description">
            <p
              class="password-form-layout__font password-form-layout__font--description"
              v-html="props.componentData?.infoText"
            />
          </div>

          <UiInput
            v-model="passwordData"
            name="password"
            label="Пароль"
            placeholder="Пароль"
            :type="'password'"
            is-toggle-password
            class="password-form-layout__item"
          />

          <UiInput
            v-model="passwordConfirmationData"
            name="password_confirmation"
            label="Подтверждение пароля"
            placeholder="Подтверждение пароля"
            :type="'password'"
            is-toggle-password
            class="password-form-layout__item"
          />
        </div>
      </template>

      <template #footer>
        <div class="password-form-layout__footer">
          <UiButtonWithLoader
            type="submit"
            class="password-form-layout__button password-form-layout__button--submit"
            text="Сохранить"
            :is-loading="isSubmitting || globalStore.requestsProgress"
          />

          <UiButtonIcon
            theme="transparent"
            text="Удалить анкету"
            class="confirmation-email-form__button confirmation-email-form__button--delete"
            @click="deleteQuestionnaire"
          />
        </div>
      </template>
    </FormLayout>
  </VeeForm>
</template>
<style scoped lang="scss">
.password-form-layout {
  &__font {
    &--step {
      font-size: em(18);
      line-height: 1.3;
      color: $color-grey-3;
    }

    &--description {
      font-size: em(18);
      line-height: 1.3;

      &:deep(strong) {
        font-weight: 700;
      }
    }
  }

  &__description {
    margin-bottom: em(24);

    @include media-breakpoint-down(sm) {
      margin-bottom: em(16);
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: em(16);
    align-items: center;
  }

  &__button {
    &--submit {
      width: 100%;
    }
  }
}
</style>
