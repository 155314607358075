<script setup>
import UiLinkTag from '@/components/ui/UiLinkTag.vue';
import CIcon from '@/components/common/CIcon.vue';

const props = defineProps({
  to: {
    type: String,
    default: undefined,
  },
  target: {
    type: String,
    default: undefined,
  },
  isStaticByDefault: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: '',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: undefined,
    validator(theme) {
      return ['grey', 'white'].includes(theme);
    },
  },
});

const emit = defineEmits(['click']);

const emitClick = (e) => {
  emit('click', e);
};
</script>
<template>
  <UiLinkTag
    :to="props.to"
    :target="props.target"
    :type="props.type"
    :is-static-by-default="props.isStaticByDefault"
    class="ui-button-with-loader"
    :is-disabled="isDisabled || isLoading"
    :class="{
      [`ui-button-with-loader--theme--${theme}`]: theme,
      'ui-button-with-loader--disabled': isDisabled,
      'ui-button-with-loader--loading': isLoading,
    }"
    @click="emitClick"
  >
    <slot>
      <transition name="fade" mode="out-in">
        <span v-if="!props.isLoading" class="ui-button-with-loader__font">
          {{ text }}
        </span>

        <span v-else class="ui-button-with-loader__loader">
          <img
            src="/images/Loading.svg"
            alt="Loading"
            class="ui-button-with-loader__image"
          />

          <span class="ui-button-with-loader__font">
            Пожалуйста, подождите
          </span>
        </span>
      </transition>
    </slot>
  </UiLinkTag>
</template>

<style scoped lang="scss">
.ui-button-with-loader {
  $parent: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: em(5) em(36);
  min-height: em(64);
  text-align: center;
  background-color: $color-yellow-2;
  border-radius: em(24);
  transition: $time-default ease-out;
  transition-property: background-color, color;

  @include hover {
    background-color: $color-yellow-3;
  }

  &__font {
    font-size: em(18);
    font-weight: 500;
    line-height: 1;
  }

  &__loader {
    display: flex;
    align-items: center;
    gap: em(8);
  }

  &__image {
    flex-shrink: 0;
    width: em(24);
    height: em(24);
    animation: rotate 3s linear infinite;
  }

  &--disabled {
    color: $color-grey-2 !important;
    pointer-events: none;
    touch-action: none;
    background-color: $color-grey-1 !important;
  }

  &--loading {
    pointer-events: none;
    touch-action: none;
    background-color: $color-grey-1 !important;
  }

  &--theme {
    &--grey {
      background-color: $color-grey-1;

      @include hover {
        background-color: $color-grey-1;
        color: $color-blue-1;
      }
    }

    &--white {
      background-color: $color-white;

      @include hover {
        background-color: $color-white;
        color: $color-blue-1;
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
